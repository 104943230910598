import * as React from "react";
import { useState } from "react";
import { Button, Col, Form, ListGroup, Row, Image } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { uploadToHermesCache } from "../api/cdn_apimanager";
import { confirmScans } from "../api/orderportal_apimanager";
import { CDN_response } from "../api/types/CDN_response";
import { Customer } from "../api/types/Customer";
import { Impression } from "../api/types/Impression";
import toastManager from "./toastmanager";

interface Props {
	impression: Impression;
	tenant_id: string;
	customers: Customer[];
	timestamp: Date;
	// priority: React.JSX.Element
}

export default function ToBeScanned(props: Props) {
	const [left, setLeft] = useState<CDN_response>();
	const [right, setRight] = useState<CDN_response>();
	const [uploaded, setUploaded] = useState<boolean>();

	function handleUploadLeft(event: any) {
		upload(event.target.files[0], "left");
	}

	function handleUploadRight(event: any) {
		upload(event.target.files[0], "right");
	}

	function upload(file: any, side: string) {
		if (props.impression.customer_id)
			uploadToHermesCache(props.tenant_id, props.impression.customer_id, file).then((response) => {
				if (side === "left") setLeft(response);
				if (side === "right") setRight(response);
			});
		else {
			toastManager("error", "Something went wrong", "Please reload the page or log out and back in and try again, if problem persists please contact the administrator");
		}
	}

	function handleConfirm(event: any) {
		if (left || right) {
			const CDN_PATH = `${process.env.REACT_APP_CDN_BASEURL}/cache/hermes/${props.tenant_id}/${props.impression.customer_id}`;
			var l = left ? `${CDN_PATH}/${left?.data.document}` : "";
			var r = right ? `${CDN_PATH}/${right?.data.document}` : "";
			confirmScans(props.tenant_id, props.impression.customer_id, props.impression.id, l, r).then((response) => {
				if (response) setUploaded(true);
				else setUploaded(false);
			});
		}
	}
	return (
		<Form>
			<ListGroup.Item>
				<Row>
					<Col
						sm={2}
						className="text-truncate"
					>
						<small> {props.timestamp.toLocaleDateString()}</small>
						<br />
						<Image
							className="order-logo"
							style={{ marginRight: 10 }}
							src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${props.customers.find((e) => e.id === props.impression.customer_id)?.logo_url}&size=128`}
						/>
						{props.customers.find((e) => e.id === props.impression.customer_id)?.name}
					</Col>
					<Col sm={1}>
						<Form.Label>{props.impression.bag_code_left} </Form.Label>
					</Col>
					<Col sm={3}>
						<Form.Control
							disabled={uploaded}
							type="file"
							id={`${props.impression.bag_code_left}`}
							accept=".stl"
							onChange={handleUploadLeft}
						/>
					</Col>
					<Col sm={1}>
						{" "}
						{left?.success === true && <h6 style={{ color: "green" }}>Successful</h6>}
						{left?.success === false && <h6 style={{ color: "red" }}>Failed</h6>}
					</Col>
					<Col sm={3}>
						<Form.Control
							disabled={uploaded}
							type="file"
							id={`${props.impression.bag_code_right}`}
							accept=".stl"
							onChange={handleUploadRight}
						/>
					</Col>
					<Col sm={1}>
						{" "}
						{right?.success === true && <h6 style={{ color: "green" }}>Successful</h6>}
						{right?.success === false && <h6 style={{ color: "red" }}>Failed</h6>}
					</Col>
					<Col sm={1}>
						{(left || right) && !uploaded && <Button onClick={handleConfirm}>Confirm</Button>}
						{uploaded === true && <h6 style={{ color: "green" }}>Successful</h6>}
						{uploaded === false && <h6 style={{ color: "red" }}>Failed</h6>}
						{/* {uploaded === undefined &&
                            props.priority
                        } */}
					</Col>
					{/*<Col >
    {priority.normal}
</Col> */}
				</Row>
			</ListGroup.Item>
		</Form>
	);
}
