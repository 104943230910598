import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { addConsumer, getConsumers } from "../api/orderportal_apimanager";
import { Consumer } from "../api/types/Consumer";
import { Customer } from "../api/types/Customer";
import { Department } from "../api/types/Department";
import { ListItem } from "../api/types/ListItem";
import ConsumersList from "./consumerslist";
import toastManager from "./toastmanager";

interface Props {
	customer: Customer;
	departments: Department[];
}

export default function EndUsers(props: Props) {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const [departments, setDepartments] = useState<ListItem[] | undefined>();
	const [consumers, setConsumers] = useState<Consumer[]>([]);
	const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>(undefined);
	const [reload, setReload] = useState<boolean>(false);

	const [sortedConsumers, setSortedConsumers] = useState<Consumer[]>();

	const [firstnameFilter, setFirstnameFilter] = useState<string>("");
	const [lastnameFilter, setLastnameFilter] = useState<string>("");
	const [nicknameFilter, setNicknameFilter] = useState<string>("");
	const [departmentFilter, setDepartmentFilter] = useState<string>("");
	const [personnelFilter, setPersonnelFilter] = useState<string>("");
	const [productCountFilter, setProductCountFilter] = useState<number>(-1);
	const [sortDirection, setSortDirection] = useState<boolean>(false);
	const [sortProperty, setSortProperty] = useState<string>();

	const [totalProducts, setTotalProducts] = useState<number>(0);

	function handleDepartmentChange(event: any) {
		if (event == null) setDepartmentFilter("");
		else setDepartmentFilter(event.value);
	}

	function handleProductCount(event: any) {
		if (event == null) setProductCountFilter(-1);
		else setProductCountFilter(event.value);
	}

	function checkIfInList(first: string, last: string, short: string): boolean {
		var returnvalue = false;
		consumers.forEach((consumer) => {
			if (consumer.given_name.toLowerCase() === first.toLowerCase() && consumer.family_name.toLowerCase() === last.toLowerCase() && consumer.short_name.toLowerCase() === short.toLowerCase())
				returnvalue = true;
		});
		return returnvalue;
	}

	function handleAddUser(event: any) {
		var etf = event.target.form;
		if (!checkIfInList(etf.given_name.value, etf.family_name.value, etf.short_name.value)) {
			if (etf.given_name.value !== "" && etf.family_name.value !== "" && etf.short_name.value !== "" && selectedDepartment !== undefined) {
				addConsumer(props.customer!.id, etf.given_name.value, etf.family_name.value, etf.short_name.value, selectedDepartment!, etf.personnel_number.value).then((result) => {
					toastManager(
						"success",
						`Added ${etf.given_name.value} ${etf.family_name.value}`,
						`Successfully added ${etf.given_name.value} ${etf.family_name.value} (${etf.short_name.value}) to ${props.customer?.name}`
					);
					setReload(true);
				});
			} else {
				toastManager("warning", `Not all required fields are filled in`, `Please fill in all the required fields to continue`);
			}
		} else {
			toastManager(
				"error",
				`User already exists`,
				`${etf.given_name.value} ${etf.family_name.value} already exists. If there are 2 or more users with the same first and last name please choose a unique nickname for each of them.`
			);
		}
	}

	function GetDepartmentName(departmentID: string) {
		const found = departments?.find((dep) => {
			return dep.value === departmentID;
		});
		if (found) return found.label;
		else {
			return "No department found"; // this is possible if a department is removed
		}
	}

	function handleDepartmentChangeNewUser(event: any) {
		setSelectedDepartment(event.value);
	}

	useEffect(
		function RetrieveDepartments() {
			var deps: ListItem[] = [];
			props.departments.forEach((comp: Department) => {
				var item: ListItem = { label: comp.name, value: comp.id };
				deps.push(item);
			});
			setDepartments(deps);
		},
		[props.departments, token]
	);

	useEffect(
		function RetrieveConsumers() {
			setSortedConsumers(undefined);
			getConsumers(token, props.customer.id).then((result) => {
				var cons: Consumer[] = [];
				var total = 0;
				if (result)
					result.forEach((consumer: Consumer) => {
						total += consumer.product_count;
						cons.push(consumer);
					});
				setTotalProducts(total);
				setConsumers(cons);
				setReload(false);
			});
		},
		[token, reload, props.customer]
	);

	function SortList(property: "firstname" | "lastname" | "nickname" | "department" | "products" | "personnel") {
		setSortDirection(!sortDirection);
		setSortProperty(property);
		var temp = consumers;

		if (property === "firstname") {
			if (sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = a.given_name.toLowerCase();
					const nameB = b.given_name.toLowerCase();
					if (nameA < nameB) return -1;
					else if (nameA > nameB) return 1;
					return 0;
				});
			else if (!sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = a.given_name.toLowerCase();
					const nameB = b.given_name.toLowerCase();
					if (nameA > nameB) return -1;
					else if (nameA < nameB) return 1;
					return 0;
				});
		}
		if (property === "lastname") {
			if (sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = a.family_name.toLowerCase();
					const nameB = b.family_name.toLowerCase();
					if (nameA < nameB) return -1;
					else if (nameA > nameB) return 1;
					return 0;
				});
			else if (!sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = a.family_name.toLowerCase();
					const nameB = b.family_name.toLowerCase();
					if (nameA > nameB) return -1;
					else if (nameA < nameB) return 1;
					return 0;
				});
		}
		if (property === "nickname") {
			if (sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = a.short_name.toLowerCase();
					const nameB = b.short_name.toLowerCase();
					if (nameA < nameB) return -1;
					else if (nameA > nameB) return 1;
					return 0;
				});
			else if (!sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = a.short_name.toLowerCase();
					const nameB = b.short_name.toLowerCase();
					if (nameA > nameB) return -1;
					else if (nameA < nameB) return 1;
					return 0;
				});
		}
		if (property === "personnel") {
			if (sortDirection) {
				temp = consumers
					.filter((cons) => cons.personnel_number)
					.sort((a, b) => {
						const nameA = a.personnel_number?.toLowerCase();
						const nameB = b.personnel_number?.toLowerCase();
						if (nameA && nameB && nameA < nameB) return -1;
						else if (nameA && nameB && nameA > nameB) return 1;
						return 0;
					});
				temp.push(...consumers.filter((cons) => !cons.personnel_number));
			} else if (!sortDirection) {
				temp = consumers
					.filter((cons) => cons.personnel_number)
					.sort((a, b) => {
						const nameA = a.personnel_number?.toLowerCase();
						const nameB = b.personnel_number?.toLowerCase();
						if (nameA && nameB && nameA > nameB) return -1;
						else if (nameA && nameB && nameA < nameB) return 1;
						return 0;
					});
				temp.push(...consumers.filter((cons) => !cons.personnel_number));
			}
		}

		if (property === "department") {
			if (sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = GetDepartmentName(a.department).toLowerCase();
					const nameB = GetDepartmentName(b.department).toLowerCase();
					if (nameA < nameB) return -1;
					else if (nameA > nameB) return 1;
					return 0;
				});
			else if (!sortDirection)
				temp = consumers.sort((a, b) => {
					const nameA = GetDepartmentName(a.department).toLowerCase();
					const nameB = GetDepartmentName(b.department).toLowerCase();
					if (nameA > nameB) return -1;
					else if (nameA < nameB) return 1;
					return 0;
				});
		}

		if (property === "products") {
			if (sortDirection) temp = consumers.sort((a, b) => a.product_count - b.product_count);
			else if (!sortDirection) temp = consumers.sort((a, b) => b.product_count - a.product_count);
		}

		setSortedConsumers(temp);
	}

	if (consumers)
		return (
			<>
				{props.customer && (
					<>
						<Accordion>
							<Accordion.Item eventKey="adduser">
								<Accordion.Header>
									<Icon.PersonAdd /> Add a new user to {props.customer?.name}
								</Accordion.Header>
								<Accordion.Body>
									<Form>
										<Row>
											<Col>
												<Form.Text>First name*</Form.Text>
												<Form.Control
													type="text"
													id="given_name"
												></Form.Control>
											</Col>
											<Col>
												<Form.Text>Last name*</Form.Text>
												<Form.Control
													type="text"
													id="family_name"
												></Form.Control>
											</Col>
											<Col>
												<Form.Text>Nickname*</Form.Text>
												<Form.Control
													type="text"
													id="short_name"
												></Form.Control>
											</Col>
										</Row>
										<Row>
											<Col>
												<Form.Text>Department*</Form.Text>
												<Select
													noOptionsMessage={() => "No departments yet, please add one on the departments page before continuing"}
													isSearchable
													options={departments}
													onChange={handleDepartmentChangeNewUser}
												/>
											</Col>
											<Col>
												<Form.Text>Personnel number</Form.Text>
												<Form.Control
													type="text"
													maxLength={20}
													id="personnel_number"
												></Form.Control>
											</Col>
										</Row>
										<Form.Text className="small">*required</Form.Text>
										<br />
										<br />
										<Button onClick={handleAddUser}>
											<Icon.PersonFillAdd /> Add user to {props.customer?.name}
										</Button>
									</Form>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<hr />
						<Row>
							<h5>Filter by field</h5>
							<Col>
								<Form.Control
									placeholder="First name"
									autoComplete="off"
									type="input"
									onChange={(e) => setFirstnameFilter(e.target.value)}
								></Form.Control>
							</Col>
							<Col>
								<Form.Control
									placeholder="Last name"
									autoComplete="off"
									type="input"
									onChange={(e) => setLastnameFilter(e.target.value)}
								></Form.Control>
							</Col>
							<Col className="d-none d-lg-block ">
								<Form.Control
									placeholder="Nickname"
									autoComplete="off"
									type="input"
									onChange={(e) => setNicknameFilter(e.target.value)}
								></Form.Control>
							</Col>
							<Col>
								<Select
									noOptionsMessage={() => "No departments yet, please add one on the departments page before continuing"}
									placeholder="Department"
									isSearchable
									isClearable
									options={departments}
									onChange={handleDepartmentChange}
								/>
							</Col>
							<Col className="d-none d-lg-block ">
								<Form.Control
									placeholder="Personnel number"
									autoComplete="off"
									type="input"
									onChange={(e) => setPersonnelFilter(e.target.value)}
								></Form.Control>
							</Col>
							<Col sm={2}>
								<Select
									placeholder="Products"
									isSearchable
									isClearable
									options={[
										{ label: "0", value: 0 },
										{ label: "1", value: 1 },
										{ label: "2", value: 2 },
										{ label: "3+", value: 3 },
									]}
									onChange={handleProductCount}
								/>
							</Col>
						</Row>
						<br />

						<ListGroup
							variant="flush"
							style={{ margin: 0 }}
						>
							<ListGroup.Item>
								<Row>
									<Col
										onClick={() => SortList("firstname")}
										className="fw-bold"
									>
										{sortProperty === "firstname" && sortDirection && <Icon.SortAlphaDownAlt />}
										{sortProperty === "firstname" && !sortDirection && <Icon.SortAlphaDown />}
										First name
									</Col>
									<Col
										onClick={() => SortList("lastname")}
										className="fw-bold"
									>
										{sortProperty === "lastname" && sortDirection && <Icon.SortAlphaDownAlt />}
										{sortProperty === "lastname" && !sortDirection && <Icon.SortAlphaDown />}
										Last name
									</Col>
									<Col
										onClick={() => SortList("nickname")}
										className="fw-bold d-none d-lg-block "
									>
										{sortProperty === "nickname" && sortDirection && <Icon.SortAlphaDownAlt />}
										{sortProperty === "nickname" && !sortDirection && <Icon.SortAlphaDown />}
										Nickname
									</Col>
									<Col
										onClick={() => SortList("department")}
										className="fw-bold d-none d-lg-block "
									>
										{sortProperty === "department" && sortDirection && <Icon.SortAlphaDownAlt />}
										{sortProperty === "department" && !sortDirection && <Icon.SortAlphaDown />}
										Department
									</Col>
									<Col
										onClick={() => SortList("personnel")}
										className="fw-bold d-none d-lg-block "
									>
										{sortProperty === "personnel" && sortDirection && <Icon.SortNumericDownAlt />}
										{sortProperty === "personnel" && !sortDirection && <Icon.SortNumericDown />}
										Personnel #
									</Col>
									<Col
										onClick={() => SortList("products")}
										className="fw-bold center"
									>
										{sortProperty === "products" && sortDirection && <Icon.SortNumericDownAlt />}
										{sortProperty === "products" && !sortDirection && <Icon.SortNumericDown />}
										Products
									</Col>
								</Row>
							</ListGroup.Item>
							<ConsumersList
								consumers={sortedConsumers ?? consumers}
								departments={departments}
								firstnameFilter={firstnameFilter}
								lastnameFilter={lastnameFilter}
								nicknameFilter={nicknameFilter}
								departmentFilter={departmentFilter}
								productsFilter={productCountFilter}
								customer={props.customer.id}
								personnelFilter={personnelFilter}
							/>
							<ListGroup.Item>
								<Row>
									<Col className="d-none d-lg-block"></Col>
									<Col className="d-none d-lg-block"></Col>
									<Col className="d-none d-lg-block"></Col>
									<Col className="fw-bold ">Total products:</Col>
									<Col className="fw-bold center">{totalProducts}</Col>
								</Row>
							</ListGroup.Item>
						</ListGroup>
					</>
				)}
				{!props.customer && <p>Please select a customer</p>}
			</>
		);
	else return <></>;
}
