import React, { JSX } from "react";
import { Accordion, Col, Image, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Customer } from "../api/types/Customer";
import { Order } from "../api/types/Order";
import * as Icon from "react-bootstrap-icons";
interface Props {
	orders: Order[];
	order_id_filter: string;
	order_exact_id_filter: string;
	customers: Customer[];
	showCompleted: boolean;
}

export default function ListedOrders(props: Props) {
	function OrderListItem(order: Order, variant: string, latestStatus: string, latestDate: Date) {
		var customer = props.customers.find((e) => e.id === order.customer_id);
		var createdDate = new Date(0);
		createdDate.setUTCMilliseconds(order.created ?? 0);
		return (
			<ListGroup
				key={latestDate.getTime()}
				variant="flush"
				className="no-right-padding"
			>
				<ListGroup.Item
					key={order.id}
					variant={variant}
					action
					onClick={() => {
						navigate(`orderdetails?orderid=${order.id}`);
					}}
				>
					<Row key={order.id}>
						<Col
							className="text-truncate center"
							sm={1}
						>
							<Image
								src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${customer?.logo_url}&size=128`}
								className="order-logo"
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = "building.svg";
								}}
							/>
						</Col>
						<Col
							className="text-truncate"
							sm={3}
						>
							{customer?.name}
						</Col>
						<Col className="text-truncate">{order.referenceId}</Col>

						{order.exact_order_number !== -1 && (
							<Col
								sm={2}
								className="text-truncate center"
							>
								{order.exact_order_number}
							</Col>
						)}
						{order.exact_order_number === -1 && (
							<Col
								sm={2}
								className="text-truncate center"
							>
								{" "}
								<Icon.DashLg />
							</Col>
						)}
						<Col
							sm={2}
							className="center"
						>
							{latestStatus}
						</Col>
						<Col sm={1}>{createdDate.toLocaleDateString()}</Col>
						<Col sm={1}>{latestDate.toLocaleDateString()}</Col>
					</Row>
				</ListGroup.Item>
			</ListGroup>
		);
	}

	const navigate = useNavigate();
	var createdOrders: JSX.Element[] | undefined = [];
	var lockedOrders: JSX.Element[] | undefined = [];
	var confirmedOrders: JSX.Element[] | undefined = [];
	var inProductionOrders: JSX.Element[] | undefined = [];
	var shippingOrders: JSX.Element[] | undefined = [];
	var completedOrders: JSX.Element[] | undefined = [];
	var latestStatus = "Created";
	var latestDate = new Date(0);

	if (props.orders) {
		props.orders.forEach((order) => {
			if (order.exact_order_number === null) order.exact_order_number = -1;
		});
		props.orders
			.filter((order) => order.referenceId.toLowerCase().includes(props.order_id_filter.toLowerCase()))
			.filter((exactFilter) => exactFilter?.exact_order_number?.toString().includes(props.order_exact_id_filter))
			.forEach((filteredOrder) => {
				var created = new Date(0);
				if (filteredOrder.created) {
					latestStatus = "Created";
					created.setUTCMilliseconds(filteredOrder.created);
					latestDate = created;
				}
				var locked = new Date(0);
				if (filteredOrder.locked) {
					latestStatus = "Locked";
					locked.setUTCMilliseconds(filteredOrder.locked);
					latestDate = locked;
				}
				var confirmed = new Date(0);
				if (filteredOrder.confirmed) {
					latestStatus = "Confirmed";
					confirmed.setUTCMilliseconds(filteredOrder.confirmed);
					latestDate = confirmed;
				}
				var inProduction = new Date(0);
				if (filteredOrder.inProduction) {
					latestStatus = "In production";
					inProduction.setUTCMilliseconds(filteredOrder.inProduction);
					latestDate = inProduction;
				}
				var shipping = new Date(0);
				if (filteredOrder.shipping) {
					latestStatus = "Shipping";
					shipping.setUTCMilliseconds(filteredOrder.shipping);
					latestDate = shipping;
				}
				var completed = new Date(0);
				if (filteredOrder.completed != null) {
					latestStatus = "Completed";
					completed.setUTCMilliseconds(filteredOrder.completed);
					latestDate = completed;
				}

				if (filteredOrder.completed != null) {
					if (completedOrders !== undefined) completedOrders.push(OrderListItem(filteredOrder, "success", latestStatus, latestDate));
				} else if (filteredOrder.shipping != null) {
					if (shippingOrders !== undefined) shippingOrders.push(OrderListItem(filteredOrder, "secondary", latestStatus, latestDate));
				} else if (filteredOrder.inProduction != null) {
					if (inProductionOrders !== undefined) inProductionOrders.push(OrderListItem(filteredOrder, "info", latestStatus, latestDate));
				} else if (filteredOrder.confirmed != null) {
					if (confirmedOrders !== undefined) confirmedOrders.push(OrderListItem(filteredOrder, "primary", latestStatus, latestDate));
				} else if (filteredOrder.locked != null) {
					if (lockedOrders !== undefined) lockedOrders.push(OrderListItem(filteredOrder, "warning", latestStatus, latestDate));
				} else if (filteredOrder.created != null) {
					if (createdOrders !== undefined) createdOrders.push(OrderListItem(filteredOrder, "none", latestStatus, latestDate));
				}
			});
	}

	// Sorts the orders newest first, the key is set as the timestamp of the latest updated date
	function sortOrdersOnDate(orderArray: JSX.Element[]) {
		orderArray.sort((a, b) => {
			if (a.key && b.key) {
				return parseInt(b.key) - parseInt(a.key);
			} else return 0;
		});
		return orderArray;
	}

	return (
		<>
			{sortOrdersOnDate(createdOrders).reverse()}
			{sortOrdersOnDate(shippingOrders).reverse()}
			{sortOrdersOnDate(inProductionOrders).reverse()}
			{sortOrdersOnDate(lockedOrders).reverse()}
			{sortOrdersOnDate(confirmedOrders).reverse()}
			{props.showCompleted && sortOrdersOnDate(completedOrders)}
		</>
	);
}
