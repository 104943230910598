import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row, Tab, Tabs } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getConsumers, getCustomers, getDepartments, updateCustomer } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { ListItem } from "../api/types/ListItem";
import BackButton from "../modules/backbutton";
import Departments from "../modules/departments";
import EndUsers from "../modules/endusers";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";

import { useTenant } from "../hooks/retrieveHooks";

import { Tenant } from "../api/types/Tenant";

export default function Companies() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const queryParameters = new URLSearchParams(window.location.search);
	const customerid = queryParameters.get("customer");

	const tenant: Tenant | undefined = useTenant();

	const [editCustomerDetails, setEditCustomerDetails] = useState<boolean>(false);

	const [departments, setDepartments] = useState<[]>([]);
	const [consumers, setConsumers] = useState<[]>([]);

	const [refresh, setRefresh] = useState<boolean>(true);
	const [customers, setCustomers] = useState<Customer[]>();
	const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
	const [customersList, setCustomersList] = useState<ListItem[]>();
	function handleEditChange(event: any) {
		event.preventDefault();
		if (editCustomerDetails && selectedCustomer) {
			const etf = event.target.form;
			updateCustomer(selectedCustomer?.id, etf.name.value, etf.description.value, etf.email.value, etf.address1.value, etf.address2.value, etf.logo.value).then((response) => {
				if (response) {
					setRefresh(true);
					toastManager("success", `${etf.name.value} updated`, `Succesfully updated ${etf.name.value}`);
					event.target.form.reset();
				} else toastManager("error", "Something went wrong", ` Something went wrong when trying to update ${selectedCustomer.name}`);
			});
			// Save if changes are made
		}
		setEditCustomerDetails(!editCustomerDetails);
	}
	function handleAddCompany(event: any) {
		navigate("/company");
	}

	function handleCustomerChange(event: any) {
		setSelectedCustomer(customers?.find((e) => e.id === event.value));
	}

	useEffect(
		function hamdleReturnFromConsumer() {
			if (customerid) {
				setSelectedCustomer(customers?.find((e) => e.id === customerid));
			} else {
				if (customers) setSelectedCustomer(customers.find((e) => e.tenant_id === tenant?.id));
			}
		},
		[customerid, customers, tenant?.id]
	);

	useEffect(
		function RetrieveDepartments() {
			if (selectedCustomer !== undefined && token && token.length) {
				getDepartments(token, selectedCustomer.id).then((result) => {
					setDepartments(result);
				});
			}
		},
		[selectedCustomer, token]
	);

	useEffect(
		function RetrieveConsumers() {
			if (selectedCustomer && token && token.length)
				getConsumers(token, selectedCustomer?.id).then((result) => {
					setConsumers(result);
				});
		},
		[selectedCustomer, token]
	);

	useEffect(
		function getCustomersFromAPI() {
			if (refresh && token && token.length && tenant)
				getCustomers(token).then((response) => {
					if (response) {
						setCustomers(response);
						var tempList: ListItem[] = [];
						response.forEach((customer: Customer) => {
							if (customer.tenant_id === tenant.id)
								tempList.push({
									label: customer.name,
									value: customer.id,
								});
						});
						setCustomersList(tempList);
					}
					setRefresh(false);
				});
		},
		[refresh, tenant, token]
	);

	console.log("customers", customers);

	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="home"
						/>
						<h4 className="center">{tenant?.name}</h4>
						<h1>Customers </h1>
						<hr /> <br />
						<br />
						<br />
						<Row>
							<h3 className="center">
								<Row>
									<Col>
										<Select
											options={customersList}
											onChange={handleCustomerChange}
											defaultValue={customersList?.find((e) => e.value === customerid)}
											placeholder={selectedCustomer?.name}
										></Select>
									</Col>
									<Col xs={3}>
										<Button
											onClick={handleAddCompany}
											variant="outline-primary"
										>
											<Icon.PlusCircle /> Add a new customer
										</Button>
									</Col>
								</Row>
							</h3>
						</Row>
						<br />
						<Tabs
							defaultActiveKey="details"
							id="uncontrolled-tab-example"
							className="mb-3"
							justify
						>
							<Tab
								eventKey="details"
								title="Details"
							>
								<Form id="companyForm">
									<Row>
										<Col>
											<Row>
												<Col>
													<h4>Name:</h4>
												</Col>
												<Col>
													<Form.Control
														id="name"
														disabled={!editCustomerDetails}
														defaultValue={selectedCustomer?.name}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<h4>Description:</h4>
												</Col>
												<Col>
													<Form.Control
														id="description"
														disabled={!editCustomerDetails}
														defaultValue={selectedCustomer?.description}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<h4>Email:</h4>
												</Col>
												<Col>
													<Form.Control
														id="email"
														disabled={!editCustomerDetails}
														defaultValue={selectedCustomer?.contact_email}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<h4>AddressLine 1:</h4>
												</Col>
												<Col>
													<Form.Control
														id="address1"
														disabled={!editCustomerDetails}
														defaultValue={selectedCustomer?.address_line_1}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<h4>AddressLine2:</h4>
												</Col>
												<Col>
													<Form.Control
														id="address2"
														disabled={!editCustomerDetails}
														defaultValue={selectedCustomer?.address_line_2}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<h4>Website URL:</h4>
												</Col>
												<Col>
													<Form.Control
														id="logo"
														disabled={!editCustomerDetails}
														defaultValue={selectedCustomer?.logo_url}
														className="text-truncate"
													/>
												</Col>
											</Row>
										</Col>
										<Col
											xs={2}
											className="middle"
										>
											<Image
												fluid
												src={selectedCustomer?.logo_url}
											/>
										</Col>
									</Row>

									<Button
										onClick={handleEditChange}
										className="right"
									>
										{editCustomerDetails ? "Save changes" : "Edit details"}
									</Button>
								</Form>
							</Tab>
							<Tab
								eventKey="users"
								title={`Users (${consumers.length})`}
							>
								{selectedCustomer && (
									<EndUsers
										customer={selectedCustomer}
										departments={departments}
									/>
								)}
							</Tab>
							<Tab
								eventKey="departments"
								title={`Departments (${departments.length})`}
							>
								{selectedCustomer && (
									<Departments
										customer={selectedCustomer}
										departments={departments}
									/>
								)}
							</Tab>
						</Tabs>
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
