import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import * as orderportal_api from "../api/orderportal_apimanager";
import {
	getConsumer,
	getConsumerProducts,
	getDepartments,
	getImpressions,
	getRemovedConsumerProducts,
	getRevisions,
	removeConsumer,
	removeImpression,
	updateConsumer,
} from "../api/orderportal_apimanager";
import { Consumer } from "../api/types/Consumer";
import { Customer } from "../api/types/Customer";
import { Department } from "../api/types/Department";
import { Impression, Revision } from "../api/types/Impression";
import { ListItem } from "../api/types/ListItem";
import { UserProduct } from "../api/types/UserProduct";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import toastManager from "../modules/toastmanager";
import * as Icon from "react-bootstrap-icons";
import { toast } from "sonner";
import { generateTag } from "../api/cdn_apimanager";
import { StlViewer } from "react-stl-viewer";
export default function Profile() {
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);
	const tenant_name = localStorage.getItem("tenant_name");
	const queryParameters = new URLSearchParams(window.location.search);
	const user = queryParameters.get("user");
	const customerID = queryParameters.get("customer")!;
	const cradleID = queryParameters.get("product")!;
	const impressionID = queryParameters.get("impression")!;

	const [customer, setCustomer] = useState<Customer>();

	const [departmentsList, setDepartmentsList] = useState<ListItem[]>([]);
	const [departments, setDepartments] = useState<Department[]>();
	const [selectedDepartment, setSelectedDepartment] = useState<string>();
	const [placeholderDepartment, setPlaceholderDepartment] = useState<string>();

	const [removed, setRemoved] = useState<boolean>(false);

	const [consumer, setConsumer] = useState<Consumer>();
	const [consumerProducts, setConsumerProducts] = useState<UserProduct[]>([]);
	const [removedConsumerProducts, setRemovedConsumerProducts] = useState<UserProduct[]>([]);

	const [impressions, setImpressions] = useState<Impression[] | undefined>();
	const [latestImpression, setLatestImpression] = useState<Impression>();
	const [revisions, setRevisions] = useState<Revision[]>();

	const [reload, setReload] = useState<boolean>(true);

	const [stlURL, setStlURL] = useState<string>();
	const [errorMessage, setErrorMessage] = useState<string>();

	useEffect(
		function RetrieveCustomer() {
			if (customerID && token && token.length) {
				orderportal_api.getCustomer(token, customerID).then((response) => {
					setCustomer(response as Customer);
				});
			}
		},
		[customerID, token]
	);

	useEffect(
		function RetrieveConsumer() {
			if (user && customerID && !consumer && token && token.length) {
				getConsumer(token, customerID, user).then((response) => {
					setConsumer(response as Consumer);
				});
			}
		},
		[consumer, customerID, token, user]
	);

	useEffect(
		function RetrieveConsumerProducts() {
			if (customerID && consumer && token && token.length) {
				getConsumerProducts(token, customerID, consumer.id).then((response: any) => {
					setConsumerProducts(response);
				});
				getRemovedConsumerProducts(token, customerID, consumer.id).then((response: any) => {
					setRemovedConsumerProducts(response);
				});
			}
		},
		[consumer, customerID, token]
	);

	useEffect(
		function RetrieveDepartments() {
			if (customerID && !departments && token && token.length) {
				getDepartments(token, customerID).then((response) => {
					setDepartments(response);
				});
			}
		},
		[customerID, departments, token]
	);

	useEffect(
		function SetPlaceholderDepartment() {
			departmentsList.forEach((dep) => {
				if (dep.value === consumer?.department) {
					setPlaceholderDepartment(dep.label);
				}
			});
		},
		[consumer?.department, departmentsList]
	);

	useEffect(
		function PopulateDepartmentsList() {
			if (departments) {
				var tempList: ListItem[] = [];
				departments.forEach((department) => {
					tempList.push({ label: department.name, value: department.id });
				});
				setDepartmentsList(tempList);
			}
		},
		[departments, consumer]
	);

	function PopulateProducts(products: UserProduct[]) {
		var productsElement: JSX.Element[] | undefined = [];
		let key = 0;
		products.forEach((product) => {
			key++;
			// Convert the EPOCH times to human readable dates
			var createdUTC = new Date(0);
			createdUTC.setUTCMilliseconds(product.created);
			var createdDate = createdUTC.toLocaleDateString();
			var createdTime = createdUTC.toLocaleTimeString();
			var lastUpdatedUTC = new Date(0);
			lastUpdatedUTC.setUTCMilliseconds(product.last_updated);
			var lastUpdatedDate = lastUpdatedUTC.toLocaleDateString();
			var lastUpdatedTime = lastUpdatedUTC.toLocaleTimeString();

			var deletedDate = new Date(0);
			var deleteDate = "";
			var deletedTime = "";
			if (product.deleted) {
				deletedDate.setUTCMilliseconds(product.deleted);
				deleteDate = deletedDate.toLocaleDateString();
				deletedTime = deletedDate.toLocaleTimeString();
			}

			productsElement?.push(
				<section key={key.toString()}>
					<Accordion.Item
						className="d-none d-lg-block "
						eventKey={product.serial_cradle}
						key={key.toString() + "large"}
					>
						<Accordion.Header>
							Product No. {key} - Cradle serial: {product.serial_cradle}
						</Accordion.Header>
						<Accordion.Body>
							<Row>
								<Col>Created: </Col>
								<Col>
									{createdDate} - {createdTime}
								</Col>
								<Col>Last updated: </Col>
								<Col>
									{lastUpdatedDate} - {lastUpdatedTime}
								</Col>
							</Row>
							<Row>
								<Col>Model ID cradle: </Col>
								<Col>{product.model_id_cradle}</Col>
								<Col>Model ID earbuds: </Col>
								<Col>{product.model_id_earbud}</Col>
							</Row>
							<Row>
								<Col>Serial left: </Col>
								<Col>{product.serial_left}</Col>
								<Col>Serial right: </Col>
								<Col>{product.serial_right}</Col>
							</Row>
							<Row>
								<Col>BDA left: </Col>
								<Col>{product.bda_left}</Col>
								<Col>BDA right: </Col>
								<Col>{product.bda_right}</Col>
							</Row>
							<Row>
								<Col>Serial left shell: </Col>
								<Col>{product.left_shell_serial}</Col>
								<Col>Serial right shell: </Col>
								<Col>{product.right_shell_serial}</Col>
							</Row>
							<Row>
								<Col>Serial Cradle:</Col>
								<Col>{product.serial_cradle}</Col>
								<Col>Related PR:</Col>
								<Col>{product.related_pr}</Col>
							</Row>
							<br />
							<Row>
								<Col></Col>
								<Col sm={2}>
									{consumer && (
										<Button
											variant="danger"
											//! REMOVE ME BEFORE PROD BUILD
											// onClick={() => toastManager("info", "Currently not available", "Reporting a RMA can only be done via email at this moment")}
											onClick={() => navigate(`/rma?customerid=${customerID}&consumerid=${consumer.id}&product=${product.id}`)}
										>
											Report RMA
										</Button>
									)}
								</Col>
							</Row>
							{product.deleted && (
								<Row>
									<Col>Deleted: </Col>
									<Col>
										{deleteDate} - {deletedTime}
									</Col>
									<Col>reason: </Col>
									<Col>{product.deletion_reason}</Col>
								</Row>
							)}
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item
						className="d-block d-sm-none"
						eventKey={product.serial_cradle}
						key={key.toString() + "small"}
					>
						<Accordion.Header>
							Product No. {key} <br /> Cradle serial: {product.serial_cradle}
						</Accordion.Header>
						<Accordion.Body>
							<Row>
								<Col>Created: </Col>
								<Col>
									<small>
										{createdDate} - {createdTime}
									</small>
								</Col>
							</Row>
							<Row>
								<Col>Last updated: </Col>
								<Col>
									<small>
										{lastUpdatedDate} - {lastUpdatedTime}impression === impression.imp_serial_left || impression === impression.imp_serial_right
									</small>
								</Col>
							</Row>
							<Row>
								<Col>Model ID cradle: </Col>
								<Col>{product.model_id_cradle}</Col>
							</Row>
							<Row>
								<Col>Model ID earbuds: </Col>
								<Col>{product.model_id_earbud}</Col>
							</Row>
							<Row>
								<Col>Serial left: </Col>
								<Col>{product.serial_left}</Col>
							</Row>
							<Row>
								<Col>Serial right: </Col>
								<Col>{product.serial_right}</Col>
							</Row>
							<Row>
								<Col>BDA left: </Col>
								<Col>{product.bda_left}</Col>
							</Row>
							<Row>
								<Col>BDA right: </Col>
								<Col>{product.bda_right}</Col>
							</Row>
							<Row>
								<Col>Serial left shell: </Col>
								<Col>{product.left_shell_serial}</Col>
							</Row>
							<Row>
								<Col>Serial right shell: </Col>
								<Col>{product.right_shell_serial}</Col>
							</Row>
						</Accordion.Body>
					</Accordion.Item>
				</section>
			);
		});

		if (productsElement) {
			return <>{productsElement.reverse()}</>;
		} else return <></>;
	}

	function handleCompanyChange(event: any) {
		setSelectedDepartment(event.value);
	}

	function handleUpdateUser(event: any) {
		var etf = event.target.form;
		toastManager("info", `Updated ${consumer?.given_name} ${consumer?.family_name}`, `Successfully updated ${consumer?.given_name} ${consumer?.family_name}`);
		updateConsumer(customerID!, consumer!.id, etf.given_name.value, etf.family_name.value, etf.short_name.value, selectedDepartment, etf.personnel_number.value);
	}

	function handleRemoveUser(event: any) {
		removeConsumer(customerID!, consumer!.id).then((res) => {
			toastManager("warning", `Removed ${consumer?.given_name} ${consumer?.family_name}`, `Successfully removed ${consumer?.given_name} ${consumer?.family_name}`);
			setRemoved(true);
		});
	}
	useEffect(
		function RetrieveImpressions() {
			if (consumer && token && token.length)
				getImpressions(token, customerID, consumer.id).then((response) => {
					if (response && response.length >= 1) {
						setImpressions(response);
						setLatestImpression(response[response.length - 1]);
					} else setImpressions(undefined);
					setReload(false);
				});
			},
			[consumer, customerID, token, reload]
	);

	useEffect(
		function RetrieveRevisionsOfLatestImpression() {
			if (consumer && latestImpression && token && token.length) {
				getRevisions(token, customerID, consumer?.id, latestImpression?.id).then((response) => {
					setRevisions(response);
				});
			}
		},
		[consumer, customerID, latestImpression, token]
	);

	function ConvertLatestTimestamp(timestamp: number) {
		var date = new Date(0);
		date.setUTCMilliseconds(timestamp);
		return date.toLocaleDateString();
	}

	function handleRemoveImpression(impression: Impression) {
		var toastID = toast.message(`Remove ${impression.bag_code_left} from '${consumer?.given_name} ${consumer?.family_name}'?`, {
			action: (
				<Button
					onClick={() => {
						if (customer && consumer)
							removeImpression(token, customer?.id, consumer?.id, impression.id).then((response) => {
								if (response) {
									toast.dismiss(toastID);
									toastManager("success", "Removed impression", `Removed ${impression.bag_code_left} from ${consumer.given_name} ${consumer.family_name}`);
									setReload(true);
								}
							});
					}}
					variant="success"
				>
					<Icon.Check size={15} />
				</Button>
			),
			cancel: (
				<Button
					onClick={() => toast.dismiss(toastID)}
					variant="danger"
				>
					<Icon.X size={15} />
				</Button>
			),
			duration: 15000,
		});
	}

	function ImpressionsList() {
		var temp_array: JSX.Element[] = [];

		if (impressions)
			impressions.forEach((impression) => {
				var hasScans: boolean = false;
				if (impression.left_scan_data || impression.right_scan_data) hasScans = true;
				temp_array.push(
					<ListGroup.Item
						key={impression.id}
						variant={impressionID === impression.id ? "success" : "none"}
					>
						<Row>
							<Col>{impression.bag_code_left}</Col>
							<Col>{impression.imp_serial_left}</Col>
							<Col>{impression.imp_serial_right}</Col>
							<Col sm={1}>
								{" "}
								<Button
									className="right"
									disabled={hasScans}
									onClick={() => handleRemoveImpression(impression)}
								>
									<Icon.Trash />
								</Button>
							</Col>
						</Row>
					</ListGroup.Item>
				);
			});
		return <ListGroup>{temp_array.reverse()}</ListGroup>;
	}

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<Container className="paddingTopBottom">
					{queryParameters.get("order") ? (
						<BackButton
							route={`/orders/orderdetails?orderid=${queryParameters.get("order")}`}
							title="order"
						/>
					) : (
						<BackButton
							route={`/companies?customer=${customerID}`}
							title="companies"
						/>
					)}
					<h1>
						Profile of {consumer?.given_name} {consumer?.family_name}
					</h1>
					{!removed && (
						<Form>
							<Row>
								<Col>
									<Form.Label className="fw-bold">First name: </Form.Label>
									<Form.Control
										defaultValue={consumer?.given_name}
										type="text"
										id="given_name"
									/>
								</Col>
								<Col>
									<Form.Label className="fw-bold">Last name: </Form.Label>
									<Form.Control
										defaultValue={consumer?.family_name}
										type="text"
										id="family_name"
									/>
								</Col>
								<Col sm={2}>
									<Form.Label className="fw-bold">Short name: </Form.Label>
									<Form.Control
										defaultValue={consumer?.short_name}
										type="text"
										id="short_name"
										maxLength={4}
									/>
								</Col>
								<Col sm={3}>
									<Form.Label className="fw-bold">Personnel number: </Form.Label>
									<Form.Control
										defaultValue={consumer?.personnel_number}
										type="text"
										id="personnel_number"
										maxLength={20}
									/>
								</Col>
							</Row>
							<br />
							<Row>
								<Col>
									<Form.Label className="fw-bold">Department:</Form.Label>
									<Select
										isSearchable
										options={departmentsList}
										onChange={handleCompanyChange}
										placeholder={placeholderDepartment}
									/>
								</Col>
								<Col>
									<Form.Label className="fw-bold">Customer:</Form.Label>
									<Form.Control
										defaultValue={customer?.name}
										type="text"
										id="customer_name"
										disabled
									/>
								</Col>
								<Col>
									<Form.Label className="fw-bold">Tenant:</Form.Label>
									<Form.Control
										defaultValue={tenant_name ?? ""}
										type="text"
										id="customer_name"
										disabled
									/>
								</Col>
							</Row>
							<br />
							<Button
								className="right"
								variant="primary"
								onClick={handleUpdateUser}
							>
								UPDATE USER
							</Button>
						</Form>
					)}
					<h3 className="center">Ear data</h3>
					<Accordion defaultActiveKey={impressionID}>
						<Accordion.Item
							className="d-none d-lg-block "
							eventKey={impressionID === null ? "0" : impressionID}
							key={"0"}
						>
							<Accordion.Header>Bag code, scan and model</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col>
										<Form.Label>Latest bag code left</Form.Label>
										<Form.Control
											disabled
											type="text"
											id="left_bag_code"
											value={impressions !== undefined ? latestImpression?.bag_code_left : "No bag code"}
										/>
									</Col>
									<Col>
										<Form.Label>Latest bag code right</Form.Label>
										<Form.Control
											disabled
											type="text"
											id="right_bag_code"
											value={impressions !== undefined ? latestImpression?.bag_code_right : "No bag code"}
										/>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Label>Impression serial left</Form.Label>
										<Form.Control
											disabled
											type="text"
											id="scan_left"
											value={latestImpression !== undefined ? latestImpression?.imp_serial_left : "No serial"}
										/>
									</Col>
									<Col>
										<Form.Label>Impression serial right</Form.Label>
										<Form.Control
											disabled
											type="text"
											id="scan_right"
											value={latestImpression !== undefined ? latestImpression?.imp_serial_right : "No serial"}
										/>
									</Col>
								</Row>

								<Row>
									<Col>
										<Form.Label>Latest impression intake questions</Form.Label>
										<br />
										{latestImpression?.questions?.map((question) => (
											<Form.Control
												disabled
												value={`${question.question} - ${question.answer}`}
											/>
										))}
									</Col>
									<Col>
										<Form.Label>Latest impression intake remarks</Form.Label>
										<Form.Control
											disabled
											value={latestImpression?.remarks !== "" ? latestImpression?.remarks : "no remarks"}
										/>
									</Col>
								</Row>
								<br />
								<Row>
									<Col>
										<Form.Label>Latest model version</Form.Label>
										<Form.Control
											disabled
											type="text"
											id="model_left"
											value={revisions !== undefined && revisions.length > 0 ? revisions[revisions?.length - 1]?.version : "No current version"}
										/>
									</Col>
									<Col>
										<Form.Label>Latest model date</Form.Label>
										<Form.Control
											disabled
											type="text"
											id="model_right"
											value={revisions !== undefined && revisions.length > 0 ? ConvertLatestTimestamp(revisions[revisions?.length - 1]?.stamp) : "No latest date"}
										/>
									</Col>
								</Row>
								<br />
								{revisions && (
									<>
										<Row>
											<Col>
												{revisions[revisions?.length - 1].left_modeled_data ? (
													<Button onClick={() => setStlURL(`${revisions[revisions?.length - 1].left_modeled_data}?tag=${generateTag()}`)}>
														<Icon.EyeFill /> View left STL model
													</Button>
												) : (
													<Button disabled>
														<Icon.EyeSlashFill />
														No STL preview available for this revision
													</Button>
												)}
											</Col>

											<Col>
												{revisions[revisions?.length - 1].right_modeled_data ? (
													<Button onClick={() => setStlURL(`${revisions[revisions?.length - 1].right_modeled_data}?tag=${generateTag()}`)}>
														<Icon.EyeFill /> View right STL model
													</Button>
												) : (
													<Button disabled>
														<Icon.EyeSlashFill />
														No STL preview available for this revision
													</Button>
												)}
											</Col>
										</Row>
										{errorMessage && (
											<Row style={{ color: "red" }}>
												<br /> {errorMessage}
											</Row>
										)}
										<br />
										{stlURL && (
											<Row>
												<Col>
													<Button
														variant="danger"
														className="right"
														onClick={() => setStlURL(undefined)}
													>
														<Icon.XLg />
														Close STL preview
													</Button>
												</Col>
												<StlViewer
													url={stlURL}
													style={{ width: "100vw", height: "50vh", backgroundColor: "var(--earsonly-orange-light)", borderRadius: "15px", marginTop: "1em" }}
													orbitControls
													onError={(error) => {
														setStlURL(undefined);
														console.error(error);
														setErrorMessage(`ERROR: something went wrong while trying to preview the STL`);
													}}
												/>
											</Row>
										)}
									</>
								)}
								<br />
								<Row>
									<Col>Bag code</Col>
									<Col>Impression serial left</Col>
									<Col>Impression serial right</Col>
									<Col sm={1}></Col>
								</Row>
								<ImpressionsList />
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<br />
					{consumerProducts.length === 1 && (
						<h3 className="center">
							{consumerProducts.length} product assigned to {consumer?.given_name} {consumer?.family_name}
						</h3>
					)}
					{consumerProducts.length !== 1 && (
						<h3 className="center">
							{consumerProducts.length} products assigned to {consumer?.given_name} {consumer?.family_name}
						</h3>
					)}
					<br />
					<Accordion
						defaultActiveKey={cradleID}
						alwaysOpen
					>
						{PopulateProducts(consumerProducts)}
					</Accordion>
					<br />
					<br />
					{removedConsumerProducts.length !== 0 && (
						<>
							<h3 className="center">{removedConsumerProducts.length} product(s) removed</h3>
							<Accordion alwaysOpen>{PopulateProducts(removedConsumerProducts)}</Accordion>
						</>
					)}

					{removed && <p>USER REMOVED</p>}
					<br />
					<br />
					{/* <Button
						size="sm"
						disabled
						variant="danger"
						onClick={handleRemoveUser}
					>
						Request to remove user
					</Button> */}
				</Container>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
