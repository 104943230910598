import { Text, Document, Image, Page, StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
import { RMAType } from "../../api/types/RMAtype";

interface Props {
	tenant: string;
	customer: string;
	consumer: string;
	product: string;
	rma: RMAType;
	user: string;
    timestamp: string
    rmanumber: string
}

export const CreateRMAreport = (props: Props) => {
	const style = StyleSheet.create({
		row: {
			flexDirection: "row",
		},
		page: {
			paddingLeft: "25px",
			paddingTop: "25px",
			height: "100vh",
		},
		text: {
			fontSize: "15px",
			fontWeight: "extrabold",
		},
		text2: {
			fontSize: "12px",
		},
	});

	function GenerateRows() {
		let rows = [];
		if (props.rma.checked_data?.checks)
			for (let i = 0; i < props.rma.checked_data?.checks.length; i++) {
				rows.push(
					<View>
						<Text style={style.text2}>{props.rma.checked_data?.checks[i]}</Text>
					</View>
				);
			}
		return <>{rows}</>;
	}
	const date = new Date();
	return (
		<>
			<Document>
				<Page style={style.page}>
					<Text style={style.text}>RMA number: {props.rmanumber}</Text>
					<Text style={style.text}>RMA by: {props.user}</Text>
					<Text style={style.text}>Tenant: {props.tenant}</Text>
					<Text style={style.text}>Customer: {props.customer}</Text>
					<Text style={style.text}>Consumer: {props.consumer}</Text>
					<Text style={style.text}>Complaint: {props.rma.complaint}</Text>
					<Text style={style.text}>Remarks: {props.rma.extra_info}</Text>
					<Text style={style.text}>Date: {date.toLocaleDateString()}</Text>
                    <br/>
					<GenerateRows />
				</Page>
				-
			</Document>
		</>
	);
};
