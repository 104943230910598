import { toCanvas } from "qrcode";
import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Col, Form, Image, ListGroup, Modal, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useNavigate } from "react-router-dom";
import {
	addImpressionBagCode,
	addPrintJob,
	getConsumers,
	getImpressionAndRevisionID,
	getImpressions,
	getPrintJobs,
	getProductionOrder,
	removeProductionOrder,
	updateImpressionBagCode,
} from "../api/orderportal_apimanager";
import { Article } from "../api/types/Article";
import { Consumer } from "../api/types/Consumer";
import { Impression, Question } from "../api/types/Impression";
import { Order } from "../api/types/Order";
import { ProductionOrderType } from "../api/types/ProductionOrderType";
import toastManager from "./toastmanager";
import { toast } from "sonner";
interface Props {
	productionOrder: ProductionOrderType;
	articles: Article[];
	order: Order;
	consumers: Consumer[];
	index: number;
}

export default function ProductionOrder(props: Props) {
	const [removed, setRemoved] = useState<boolean>(false);
	const [poInfo, setPoInfo] = useState<ProductionOrderType>();
	const [infoArray, setInfoArray] = useState<JSX.Element[]>();
	const [consumers, setConsumers] = useState<Consumer[]>(props.consumers);
	const [consumer, setConsumer] = useState<Consumer>();
	const [impressions, setImpressions] = useState<Impression[]>();
	const [questions, setQuestions] = useState<string[]>();

	const chiselStatus: { [key: string]: string } = {
		created: "To be confirmed", // not really a chisel state
		SLOT_ASSIGNED: "Slot assigned",
		SLOT_MISSED: "Slot missed",
		IN_PROGRESS: "In progress",
		ON_HOLD: "On hold",
		PRINTING: "Printing",
		PRINTING_COMPLETE: "Printed",
		CANCELED: "Canceled",
		COMPLETED: "Produced",
		OBA_REQUIRED: "In progress", //? Maybe show this to the portal?
		SELECTED_FOR_PACKAGING: "Packaging",
		ORDER_PACKAGED: "Shipped",
	};

	const QRCodeWrapper = (props: { code: string }) => {
		let canvas = document.createElement("canvas");
		toCanvas(canvas, props.code);
		const barcode = canvas.toDataURL();
		return (
			<>
				{
					<Image
						style={{ width: 150 }}
						src={barcode}
					></Image>
				}
			</>
		);
	};

	var article: Article | undefined = getArticle();
	const [extraArticles, setExtraArticles] = useState<any>();
	const [showModelInstructions, setShowModelInstructions] = useState<boolean>(false);
	const handleCloseModelInstructions = () => setShowModelInstructions(false);
	const handleOpenModelInstructions = () => setShowModelInstructions(true);
	const [status, setStatus] = useState<"modeling_complete" | "delivered" | "produced" | "shipped" | "none">("none");
	const [showOrderDetails, setShowDetails] = useState<boolean>(false);
	const handleCloseDetails = () => setShowDetails(false);
	const handleOpenDetails = () => setShowDetails(true);

	const [showEarshellSelect, setShowEarshellSelect] = useState<boolean>(false);
	const handleCloseEarshellSelect = () => setShowEarshellSelect(false);
	const handleOpenEarshellSelect = () => setShowEarshellSelect(true);

	const [showScanner, setShowScanner] = useState<boolean>(false);
	const [impressionCode, setImpressionCode] = useState<string>();
	let chisel = process.env.REACT_APP_CHISEL_LINK;

	const [reload, setReload] = useState<boolean>(true);

	const token = JSON.parse(localStorage.getItem("accessToken")!);
	const navigation = useNavigate();

	function getArticle() {
		var returnarticle = undefined;
		props.articles.forEach((article: Article) => {
			if (props.productionOrder.exact_article_id === article.item_code) returnarticle = article;
		});
		return returnarticle;
	}

	function getExtraArticle(articleID: string): Article | undefined {
		return props.articles.find((article: Article) => {
			return articleID.toLowerCase() === article.item_code.toLowerCase();
		});
	}

	function removeFromOrder() {
		removeProductionOrder(props.order.id, props.productionOrder.id).then((result) => {
			if (result && consumer && article !== undefined) {
				toastManager("warning", `Removed from current order`, `Removed ${article.description} of ${consumer.given_name} ${consumer.family_name} from this order`);
				setRemoved(true);
			} else {
				console.error(result);
			}
		});
	}

	function AlreadyAddedBagCodeAndNoScanToday(): boolean | undefined {
		if (impressions && impressions[impressions.length - 1].id) {
			if (impressions[impressions.length - 1].left_scan_data !== "" || impressions[impressions.length - 1].right_scan_data !== "") {
				// A scan was added to this impression
				return false;
			}
			var idDate = new Date(parseInt(impressions[impressions.length - 1].id.substring(0, 8), 16) * 1000).setHours(0, 0, 0, 0);
			var today = new Date().setHours(0, 0, 0, 0);
			return today === idDate;
		}
	}

	function shouldBeChecked(questionNumber: number, answer: "Yes" | "No"): boolean {
		if (impressions && impressions.length && impressions[impressions.length - 1].questions && impressions[impressions.length - 1].questions!.length > 0) {
			return impressions[impressions!.length - 1]!.questions![questionNumber - 1].answer === answer;
		}
		return false;
	}

	function handleAddBagNumbers(event: any) {
		var etf = event.target.form;
		if (etf.bag_code.value !== "" && etf.method.value !== "") {
			if (consumer && questions) {
				var q: Question[] = [
					{ question: questions[0], answer: etf.Q1yes.checked ? "Yes" : "No" },
					{ question: questions[1], answer: etf.Q2yes.checked ? "Yes" : "No" },
					{ question: questions[2], answer: etf.Q3yes.checked ? "Yes" : "No" },
					{ question: questions[3], answer: etf.Q4yes.checked ? "Yes" : "No" },
					{ question: questions[4], answer: etf.Q5yes.checked ? "Yes" : "No" },
					{ question: questions[5], answer: etf.Q6yes.checked ? "Yes" : "No" },
				];

				if (AlreadyAddedBagCodeAndNoScanToday() && impressions) {
					updateImpressionBagCode(
						props.order.customer_id,
						consumer.id,
						etf.bag_code.value,
						etf.bag_code.value,
						etf.method.value,
						q,
						etf.remarks.value ?? "",
						impressions[impressions?.length - 1].id
					)
						.then((response) => {
							if (response) {
								handleCloseEarshellSelect();

								setReload(true);
								toastManager("success", "Successfully updated impression", `successfully updated the latest impression`);
							}
						})
						.catch((error) => {
							console.log("error", error);
						});
				} else {
					addImpressionBagCode(props.order.customer_id, consumer.id, etf.bag_code.value, etf.bag_code.value, etf.method.value, q, etf.remarks.value ?? "")
						.then((response) => {
							if (response) {
								handleCloseEarshellSelect();
								toastManager("success", "Bag serialnumber added", `successfully added bag serialnumber to ${consumer.given_name} ${consumer.family_name}`);
								addPrintJob(props.order.id, props.productionOrder.id, response.revisions[0].id).then((response) => {
									setReload(true);
								});
							}
						})
						.catch((error) => {
							console.log("error", error);
						});
				}
			}
		} else {
			toastManager("warning", "Not all the required fields are filled in", "Please fill in all the required fields to continue");
		}
	}

	function GetConsumers() {
		getConsumers(token, props.order.customer_id).then((response) => {
			setConsumers(response);
		});
	}

	function handleRemoveProductionOrder() {
		var toastID = toast.message(`Remove production order of ${consumer?.given_name} ${consumer?.family_name} with product ${article?.description}  `, {
			action: (
				<Button
					onClick={() => {
						removeFromOrder();
						toast.dismiss(toastID);
					}}
					variant="success"
				>
					<Icon.Check size={15} />
				</Button>
			),
			cancel: (
				<Button
					onClick={() => toast.dismiss(toastID)}
					variant="danger"
				>
					<Icon.X size={15} />
				</Button>
			),
			duration: 15000,
			position: "top-center",
		});
	}

	useEffect(
		function SetQuestions() {
			if (consumer) {
				setQuestions([
					`1: Does ${consumer.given_name} have or has had tubes in their ears?`,
					`2: Is ${consumer.given_name} currently suffering from an ear infection?`,
					`3: Does ${consumer.given_name} have or has had other problems with their ears?`,
					`4: Is ${consumer.given_name} suffering from hearing loss?`,
					`5: Does ${consumer.given_name} use a hearing aid?`,
					`6: Does ${consumer.given_name} use a smartwatch? If so, what brand?`,
				]);
			}
		},
		[consumer]
	);
	useEffect(() => {
		if (consumers)
			consumers.forEach((cons) => {
				if (cons.id === props.productionOrder.consumer_id) {
					setConsumer(cons);
				}
			});
	}, [consumers, props.productionOrder.consumer_id]);

	useEffect(() => {
		if (consumer && reload) {
			getImpressions(token, props.order.customer_id, consumer.id).then((response) => {
				if (response && response.length !== 0) {
					setImpressions(response);
					setImpressionCode(response[response.length - 1].bag_code_left);
				} else setImpressions(undefined);
				setReload(false);
			});
		}
	}, [consumer, props.order.customer_id, props.order.id, props.productionOrder.id, reload, token]);

	useEffect(() => {
		if (consumer) {
			if (props.order.locked === null && poInfo?.exact_article_id) {
				getImpressionAndRevisionID(token, props.order.customer_id, consumer.id, poInfo?.exact_article_id)
					.then((response) => {
						if (response) {
							addPrintJob(props.order.id, props.productionOrder.id, response.revision);
						}
					})
					.catch((error) => console.log("error", error));
			}
		}
	}, [consumer, impressions, poInfo?.exact_article_id, props.order.customer_id, props.order.id, props.order.locked, props.productionOrder.id, token]);

	// Retrieve information when there is an order
	useEffect(() => {
		if (!poInfo) {
			var temp: any = [];
			getProductionOrder(token, props.order.id, props.productionOrder.id).then((response: ProductionOrderType) => {
				setPoInfo(response);
				response.extra_exact_articles.forEach((extraArticle) => {
					temp.push(`${extraArticle.quantity ?? 1}x ${getExtraArticle(extraArticle.selected_article_exact_id)?.description}`);
					temp.push(<br />);
				});
			});
			setExtraArticles(temp);
		}
	}, [poInfo, props.order.id, props.productionOrder.id, token]);

	// Map the info in the poInfo to an array
	useEffect(() => {
		if (poInfo) {
			var productionInformationArray: JSX.Element[] = [];
			poInfo.status.forEach((orderstatus) => {
				if (orderstatus.data && orderstatus.data.status) {
					var date = new Date(0);
					date.setUTCSeconds(orderstatus.stamp / 1000);
					productionInformationArray.push(
						<Row
							key={orderstatus.stamp}
							className="center text-capitalize"
						>
							<Form.Label key={orderstatus.stamp}>
								<h2>{chiselStatus[orderstatus.data.status] ?? ""}</h2>
								<h6>
									{date.toLocaleDateString()} @ {date.toLocaleTimeString()}
								</h6>
								<hr />
							</Form.Label>
						</Row>
					);
				} else if (!orderstatus.data && orderstatus.state) {
					var date = new Date(0);
					date.setUTCSeconds(orderstatus.stamp / 1000);
					productionInformationArray.push(
						<Row
							key={orderstatus.stamp}
							className="center text-capitalize"
						>
							<Form.Label key={orderstatus.stamp}>
								<h2>{chiselStatus[orderstatus.state] ?? ""}</h2>
								<h6>
									{date.toLocaleDateString()} @ {date.toLocaleTimeString()}
								</h6>
								<hr />
							</Form.Label>
						</Row>
					);
				}
			});
			setInfoArray(productionInformationArray);
		}
	}, [poInfo]);

	useEffect(
		function GetStatus() {
			if (props.productionOrder.delivered) setStatus("delivered");
			else if (props.productionOrder.shipped) setStatus("shipped");
			else if (props.productionOrder.produced) setStatus("produced");
			else if (props.productionOrder.print_job?.modeling_complete) setStatus("modeling_complete");
			else setStatus("none");
		},
		[props.productionOrder.delivered, props.productionOrder.print_job?.modeling_complete, props.productionOrder.produced, props.productionOrder.shipped]
	);
	if (!removed && consumer && article && questions) {
		return (
			<>
				<ListGroup.Item
					variant={props.index % 2 === 0 ? "none" : "secondary"}
					key={poInfo?.id}
				>
					<Row>
						<Col>
						{!props.order.locked &&
							<Button
							onClick={handleRemoveProductionOrder}
								variant="outline-primary"
							>
								<Icon.Trash
									color="red"
									/>
							</Button>
								}
							<Button
								variant="outline-primary"
								onClick={() => navigation(`/profile?user=${consumer.id}&customer=${props.order.customer_id}&order=${props.order.id}`)}
							>
								{consumer.given_name} {consumer.family_name}
							</Button>
							<br />
							{status === "delivered" && (
								<Button
									size="sm"
									disabled
									variant="success"
								>
									Delivered
								</Button>
							)}
							{status === "shipped" && (
								<Button
									size="sm"
									disabled
									variant="primary"
								>
									Shipped
								</Button>
							)}
							{status === "produced" && (
								<Button
									size="sm"
									disabled
									variant="dark"
								>
									Produced
								</Button>
							)}
							{status === "modeling_complete" && (
								<Button
									size="sm"
									disabled
									variant="info"
								>
									Modeling complete
								</Button>
							)}
							{status === "none" && (
								<Button
									size="sm"
									disabled
									variant="secondary"
								>
									Requires modeling
								</Button>
							)}
						</Col>
						{poInfo && (
							<>
								<Col>
									{poInfo?.chisel_pr ? (
										<a
											className="link-as-button"
											target="_blank"
											href={`${chisel}/order/${[poInfo?.chisel_pr]}`}
											rel="noreferrer"
										>
											{poInfo?.chisel_pr}
										</a>
									) : (
										"Not assigned"
									)}
								</Col>
								<Col>
									<Button
										variant="outline-primary"
										onClick={handleOpenDetails}
									>
										{poInfo.status[poInfo.status.length - 1].data ? (
											<div className="text-truncate">{chiselStatus[poInfo.status[poInfo.status.length - 1].data.status] ?? "View status"}</div>
										) : (
											<>{poInfo.status[0].state}</>
										)}
									</Button>
								</Col>
							</>
						)}
						<Col className="d-none d-sm-block">
							{" "}
							<>{(article as Article).description}</>
						</Col>
						<Col>
							<small>{extraArticles}</small>
						</Col>
						<Col sm={1}>
							<Button
								variant="outline-primary"
								onClick={handleOpenEarshellSelect}
								disabled={!!props.order.locked}
							>
								{impressions === undefined ? <Icon.Ear /> : <Icon.EarFill />}
							</Button>
						</Col>
					</Row>
				</ListGroup.Item>
				<Modal
					show={showOrderDetails}
					onHide={handleCloseDetails}
				>
					<Modal.Header
						key={`${consumer.id}modalH`}
						closeButton
					>
						{" "}
						<b>
							{consumer.given_name} {consumer.family_name}{" "}
						</b>
					</Modal.Header>
					<Modal.Body>{infoArray}</Modal.Body>
					<Modal.Footer>
						{" "}
						<b>{(article as Article).description} </b>
					</Modal.Footer>
				</Modal>
				<Modal
					show={showEarshellSelect}
					onHide={handleCloseEarshellSelect}
					size="lg"
				>
					<Modal.Header closeButton>
						{" "}
						<b>
							{consumer.given_name} {consumer.family_name}{" "}
						</b>
					</Modal.Header>
					<Modal.Body>
						<Form.Group>
							{AlreadyAddedBagCodeAndNoScanToday() && (
								<>
									<Alert variant="warning">
										A bag serialnumber has already been added to this user today. <br /> Adding a new impression will override the previous one.
									</Alert>
								</>
							)}
							<Form>
								<Row>
									<Col>
										<Form.Label>
											Serialnumber of bag
											<sup>
												<small>(1-13 characters)</small>
											</sup>
										</Form.Label>
										<Row>
											<Col>
												<Form.Control
													minLength={1}
													maxLength={13}
													type="text"
													id="bag_code"
													onChange={(event) => setImpressionCode(event.target.value)}
													value={impressionCode}
												/>
											</Col>
											<Col>
												{showScanner ? (
													<>
														<div
															onClick={() => setShowScanner(false)}
															className="fw-bold"
														>
															<Icon.X /> Close Scanner
														</div>
														<BarcodeScannerComponent
															width={500}
															height={500}
															onUpdate={(err, result) => {
																if (result) {
																	setImpressionCode(result.getText());
																	setShowScanner(false);
																}
															}}
														/>
													</>
												) : (
													<Icon.CameraFill onClick={() => setShowScanner(true)} />
												)}
											</Col>
										</Row>
										<Row>
											<Col>{impressionCode && <QRCodeWrapper code={impressionCode} />}</Col>
											<Col style={{ alignContent: "center" }}>
												<h2>{impressionCode}</h2>
											</Col>
										</Row>
									</Col>
									<Col>
										<Form.Label>
											Method
											<Icon.Info onClick={handleOpenModelInstructions} />
										</Form.Label>
										<Form.Control
											minLength={1}
											maxLength={20}
											defaultValue={impressions !== undefined ? impressions[impressions?.length - 1].method : "Dreve Otoform AK"}
											type="text"
											id="method"
										/>
									</Col>
								</Row>
								<br />
								<>
									All questions need to be answered, if nothing is selected 'no' will be chosen by default. <br /> Remarks are optional
								</>
								<br />
								<br />
								<Row key={"Q1"}>
									<Col sm={8}>
										<Form.Label>{questions[0]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q1"}
											id={"Q1yes"}
											label={"Yes"}
											defaultChecked={shouldBeChecked(1, "Yes")}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q1"}
											id={"Q1no"}
											label={"No"}
											defaultChecked={shouldBeChecked(1, "No")}
										/>
									</Col>
								</Row>
								<Row key={"Q2"}>
									<Col sm={8}>
										<Form.Label>{questions[1]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q2"}
											id={"Q2yes"}
											label={"Yes"}
											defaultChecked={shouldBeChecked(2, "Yes")}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q2"}
											id={"Q2no"}
											label={"No"}
											defaultChecked={shouldBeChecked(2, "No")}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q3"}>
									<Col sm={8}>
										<Form.Label>{questions[2]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q3"}
											id={"Q3yes"}
											label={"Yes"}
											defaultChecked={shouldBeChecked(3, "Yes")}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q3"}
											id={"Q3no"}
											label={"No"}
											defaultChecked={shouldBeChecked(3, "No")}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q4"}>
									<Col sm={8}>
										<Form.Label>{questions[3]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q4"}
											id={"Q4yes"}
											label={"Yes"}
											defaultChecked={shouldBeChecked(4, "Yes")}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q4"}
											id={"Q4no"}
											label={"No"}
											defaultChecked={shouldBeChecked(4, "No")}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q5"}>
									<Col sm={8}>
										<Form.Label>{questions[4]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q5"}
											id={"Q5yes"}
											label={"Yes"}
											defaultChecked={shouldBeChecked(5, "Yes")}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q5"}
											id={"Q5no"}
											label={"No"}
											defaultChecked={shouldBeChecked(5, "No")}
										/>
									</Col>
									<br />
								</Row>
								<Row key={"Q6"}>
									<Col sm={8}>
										<Form.Label>{questions[5]}</Form.Label>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q6"}
											id={"Q6yes"}
											label={"Yes"}
											defaultChecked={shouldBeChecked(6, "Yes")}
										/>
									</Col>
									<Col sm={1}>
										<Form.Check
											required
											inline
											type={"radio"}
											name={"Q6"}
											id={"Q6no"}
											label={"No"}
											defaultChecked={shouldBeChecked(6, "No")}
										/>
									</Col>
									<br />
								</Row>
								<Form.Label>Remarks:</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
									id="remarks"
									defaultValue={impressions ? impressions[impressions.length - 1].remarks : ""}
								></Form.Control>
								<br />
								<Button
									className="right"
									onClick={handleAddBagNumbers}
								>
									{AlreadyAddedBagCodeAndNoScanToday() ? "Update" : "Add new"} impression
								</Button>
							</Form>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className=" ">
						{" "}
						<b>{(article as Article).description} </b>
					</Modal.Footer>
				</Modal>
				<Modal
					size="xl"
					show={showModelInstructions}
					onHide={handleCloseModelInstructions}
				>
					<Modal.Header closeButton>
						<h2>Impression instructions</h2>
					</Modal.Header>
					<Modal.Body>
						<object
							className="pdf-modal"
							data="/Voorwaarden_voor_Dopple_Protect_input_model.pdf"
							type="application/pdf"
							width="100%"
							height="100%"
						>
							<p>Open this page on desktop to view the PDF!</p>
						</object>
					</Modal.Body>
				</Modal>
			</>
		);
	} else if (consumer && article) {
		return (
			<ListGroup.Item>
				<Row>
					<Col>
						<del>
							{consumer.given_name} {consumer.family_name}
						</del>
					</Col>
					<Col>
						<del>{(article as Article).description}</del>
					</Col>
					<Col>
						<del>Removed</del>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	} else {
		if (!props.consumers) GetConsumers();
		return <></>;
	}
}
