import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Image, FloatingLabel, Form, ListGroup, Modal, Offcanvas, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import BackButton from "../modules/backbutton";
import Navigation from "../modules/navigation";
import NotSignedIn from "../modules/notsignedin";
import { ListItem } from "../api/types/ListItem";
import Select from "react-select";
import toastManager from "../modules/toastmanager";
import { getConsumerProducts, getConsumers, getCustomers, getTenants, postProductRMA } from "../api/orderportal_apimanager";
import { Customer } from "../api/types/Customer";
import { Consumer } from "../api/types/Consumer";
import { UserProduct } from "../api/types/UserProduct";
import { RMAType } from "../api/types/RMAtype";
import { createCDNClient, getImageFromHermesCache, uploadToHermesCache } from "../api/cdn_apimanager";
import { CDN_response } from "../api/types/CDN_response";
import { ImageType } from "../api/types/ImageType";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CreateRMAreport } from "../modules/pdf/createRMAreport";
import { Tenant } from "../api/types/Tenant";

export default function RMA() {
	type UploadedImage = {
		document_id: string;
		file: any;
	};
	const { isAuthenticated, user } = useAuth0();
	const navigate = useNavigate();
	const token = JSON.parse(localStorage.getItem("accessToken") ?? "{}");
	useEffect(() => {
		if (!token.length) navigate("/home");
	}, [navigate, token.length]);

	const tenantID = localStorage.getItem("tenant");
	createCDNClient(token);
	const queryParameters = new URLSearchParams(window.location.search);
	const URLcustomerid = queryParameters.get("customerid");
	const URLconsumerid = queryParameters.get("consumerid");
	const URLproductid = queryParameters.get("product");

	const [tenant, setTenant] = useState<Tenant>();
	const [remark, setRemark] = useState();
	const [customers, setCustomers] = useState<Customer[]>();
	const [customersList, setCustomersList] = useState<ListItem[]>();
	const [selectedCustomer, setSelectedCustomer] = useState<ListItem>();

	const [consumers, setConsumers] = useState<Consumer[]>();
	const [consumersList, setConsumersList] = useState<ListItem[]>();
	const [selectedConsumer, setSelectedConsumer] = useState<ListItem | undefined | null>();
	const [receivedConsumers, setReceivedConsumers] = useState<boolean>(false);

	const [products, setProducts] = useState<UserProduct[]>();
	const [productsList, setProductsList] = useState<ListItem[]>();
	const [selectedProduct, setSelectedProduct] = useState<ListItem | undefined | null>();
	const [receivedProducts, setReceivedProducts] = useState<boolean>(false);

	const [images, setImages] = useState<UploadedImage[]>([]);
	const [uploadedImagesPreview, setUploadedImagesPreview] = useState<JSX.Element[]>([]);
	const [reloadImagesPreview, setReloadImagesPreview] = useState<boolean>(false);
	const [canDownloadReport, setCanDownloadReport] = useState<boolean>(false);
	const [checks, setChecks] = useState<any>([]);
	const [lastRMA, setLastRMA] = useState<RMAType>();
	const [rmaNumber, setRmaNumber] = useState<string>();
	const [timestamp, setTimestamp] = useState<string>();
	const [selectedProblem, setSelectedProblem] = useState<"no_hearing_protection_modes" | "no_charging" | "no_sound_or_music" | "no_call" | "no_connect" | "not_satisfied" | "other">();
	const problems: ListItem[] = [
		{ label: "Can't charge", value: "no_charging" },
		{ label: "Hearing protection modes do not work", value: "no_hearing_protection_modes" },
		{ label: "No sound/cannot play music", value: "no_sound_or_music" },
		{ label: "Can't call", value: "no_call" },
		{ label: "Can't connect", value: "no_connect" },
		{ label: "Unsatisfied with product/quality", value: "not_satisfied" },
		{ label: "Other...", value: "other" },
	];

	async function uploadFileToCache(file: any) {
		var temp_images: UploadedImage[] = images;
		if (tenantID && selectedCustomer?.value)
			uploadToHermesCache(tenantID, selectedCustomer?.value, file).then((response: CDN_response) => {
				temp_images.push({ file: file, document_id: response.data.document });
				setReloadImagesPreview(true);
			});
		setImages(temp_images);
	}

	async function handleUpload(event: any) {
		for (let index = 0; index < event.target.files.length; index++) {
			const file = event.target.files[index];
			uploadFileToCache(file);
		}
	}
	function handleSelectIssue(issue: string) {
		// These checks should always be done
		switch (issue) {
			case "no_charging":
				setChecks([defaultChecks, chargingChecks]);
				setSelectedProblem(issue);
				break;
			case "no_hearing_protection_modes":
				setChecks([defaultChecks, hearingProtectionModesChecks, hardwareResetFirmwareUpdate]);
				setSelectedProblem(issue);
				break;
			case "no_sound_or_music":
				setChecks([defaultChecks, noSoundOrMusicCheck, hardwareResetFirmwareUpdate]);
				setSelectedProblem(issue);
				break;
			case "no_call":
				setChecks([defaultChecks, noCallCheck, hardwareResetFirmwareUpdate]);
				setSelectedProblem(issue);
				break;
			case "no_connect":
				setChecks([defaultChecks, conectionIssuesCheck, hardwareResetFirmwareUpdate]);
				setSelectedProblem(issue);
				break;
			case "not_satisfied":
				setSelectedProblem(issue);
				setChecks([]);
				break;
			case "other":
				setChecks([defaultChecks, chargingChecks, hearingProtectionModesChecks, noSoundOrMusicCheck, noCallCheck, conectionIssuesCheck, hardwareResetFirmwareUpdate]);
				setSelectedProblem(issue);
				break;
			default:
				toastManager("error", "Something went wrong", "Please contact the administrator. This issue should not exist");
				break;
		}
	}

	useEffect(
		function PreviewImages() {
			var temp: JSX.Element[] = [];
			images.map((image) =>
				temp.push(
					<Col
						sm={1}
						className="image-preview"
					>
						<Image
							thumbnail
							src={URL.createObjectURL(image.file)}
							onClick={(event: any) => {
								var t = images.filter((e) => e.document_id !== event.target.id);
								setImages(t);
							}}
							id={image?.document_id}
						/>
					</Col>
				)
			);
			setUploadedImagesPreview(temp);
			setReloadImagesPreview(false);
		},
		[images, reloadImagesPreview]
	);

	function completeRMA(event: any) {
		const etf = event.target.form;
		const formData = new FormData(etf);

		let imageStrings: string[] = [];
		images.forEach((imagefile: UploadedImage) => {
			imageStrings.push(`${process.env.REACT_APP_CDN_BASEURL}/cache/hermes/${imagefile.document_id}`);
		});

		var answeredQuestions: string[] = [];
		for (const key of formData.keys()) {
			if (key) answeredQuestions.push(key);
		}
		if (etf.ntf.checked === etf.return.checked) {
			toastManager("error", "NTF/Return not selected", "No Trouble Found or Return to EarsOnly needs to be checked");
		}
		if (selectedConsumer && selectedCustomer && selectedProduct && selectedProblem) {
			var rma: RMAType = {
				rma_number: "12",
				complaint: selectedProblem,
				extra_info: etf["remarks"].value,
				checked_data: {
					uploaded_images: imageStrings,
					no_more_trouble_found: etf.ntf.checked,
					checks: answeredQuestions,
				},
			};
			console.log("rma", rma);
			postProductRMA(token, selectedCustomer.value, selectedConsumer.value, selectedProduct.value, rma)
				.then((response) => {
					setLastRMA(rma);
					setCanDownloadReport(true);
					setRmaNumber(response.timestamp);
					setRmaNumber(response.timestamp);
					etf.ntf.checked
						? toastManager("success", "RMA was sent successfully", "RMA was successfully sent to EarsOnly, no more action required.")
						: toastManager("success", "RMA successful", "Please return the product to EarsOnly and add the downloaded RMA form into the box");
				})
				.catch((error) => toastManager("error", "RMA unsuccessful", "Something went wrong, please try again"));
		}
	}
	function handleCustomerChange(event: any) {
		setReceivedConsumers(false);
		setReceivedProducts(false);
		setSelectedConsumer(null);
		setSelectedProduct(null);
		setSelectedCustomer(event);
	}
	function handleConsumerChange(event: any) {
		setSelectedConsumer(event);
		setReceivedProducts(false);
		setSelectedProduct(null);
	}

	const hardwareResetFirmwareUpdate = [
		<Row style={{ paddingBottom: 20 }}>
			<h5>Hardware reset </h5>
			<Col>
				<Form.Label className="fw-bold">1. Make sure the charging case battery is above 25% and both earbuds are in the charging case</Form.Label>
				<br />
				<Form.Label className="fw-bold">2. Press and hold the button on the charging case until all three lights start blinking (3-10 seconds)</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">3. Quickly double press the Charging case button. The LEDs should now blink in a different color</Form.Label>
				<Form.Check
					type="switch"
					label="LEDs start blinking orange, hardware reset completed"
					name="LEDs start blinking orange, hardware reset completed"
					id="LEDs start blinking orange, hardware reset completed"
				/>
			</Col>
		</Row>,
		<Row style={{ paddingBottom: 20 }}>
			<h5>Firmware update</h5>
			<Col>
				<Form.Label className="fw-bold">1. Connect the earbuds with your phone</Form.Label>
				<br />
				<Form.Label className="fw-bold">2. Open the MyEarsOnly app on your phone</Form.Label>
				<br />
				<Form.Label className="fw-bold">3. Wait until the app shows that the earbuds/cradle are connected</Form.Label>
				<br />
				<Form.Label className="fw-bold">4. Go into settings</Form.Label>
				<br />
				<Form.Label className="fw-bold">5. Go to Software-update</Form.Label>
			</Col>
		</Row>,
		<br />,
		<Row>
			<Col>
				<Form.Label className="fw-bold">Check if earbuds are up-to-date, if not update the earbuds</Form.Label>
				<Form.Check
					type="switch"
					label="Earbuds are up-to-date"
					id="Earbuds are up-to-date"
					name="Earbuds are up-to-date"
				/>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Check if charging case is up-to-date, if not update the charging case</Form.Label>
				<Form.Check
					type="switch"
					label="Charging case is up-to-date"
					id="Charging case is up-to-date"
					name="Charging case is up-to-date"
				/>
			</Col>
		</Row>,
		<hr />,
		<Row>
			<Col>
				<Form.Label className="fw-bold">Please retry the steps before the hardware reset</Form.Label>
				<Col>
					<Form.Check
						inline
						label="Hardware reset & updating resolved the problems"
						name="Hardware reset & updating resolved the problems"
						id="Hardware reset & updating resolved the problems"
					/>
					<Form.Check
						inline
						label="Hardware reset & updating DID NOT resolve the problems"
						name="Hardware reset & updating DID NOT resolve the problems"
						id="Hardware reset & updating DID NOT resolve the problems"
					/>
				</Col>
			</Col>
		</Row>,
		<hr />,
	];

	const defaultChecks = [
		<>
			<h5>
				Check if the following items have any visible damage and/or show signs of misuse*: <br />
				<br />
			</h5>
			<Form.Control
				hidden
				name="\/---DEFAULT CHECKS---\/"
			/>

			<Row style={{ paddingBottom: 20 }}>
				<Col>
					<Form.Label className="fw-bold">Left earbud</Form.Label>
					<Form.Check
						label="Damage/misuse found"
						name="Damaged earbud left"
						id="Damaged earbud left"
					/>
				</Col>
				<Col>
					<Form.Label className="fw-bold">Right earbud</Form.Label>
					<Form.Check
						label="Damage/misuse found"
						name="Damaged earbud right"
						id="Damaged earbud right"
					/>
				</Col>
				<Col>
					<Form.Label className="fw-bold">Cradle</Form.Label>
					<Form.Check
						label="Damage/misuse found"
						name="Damaged cradle"
						id="Damaged cradle"
					/>
				</Col>
			</Row>
			<Row style={{ paddingBottom: 20 }}>
				<Col>
					<Form.Label className="fw-bold">Charge pins left earbud</Form.Label>
					<Form.Check
						label="Damage/misuse found"
						name="Damaged charging pins left"
						id="Damaged charging pins left"
					/>
				</Col>
				<Col>
					<Form.Label className="fw-bold">Charge pins right earbud</Form.Label>
					<Form.Check
						label="Damage/misuse found"
						name="Damaged charging pins right"
						id="Damaged charging pins right"
					/>
				</Col>
				<Col>
					<Form.Label className="fw-bold">Charge port cradle</Form.Label>
					<Form.Check
						label="Damage/misuse found"
						name="Damaged charging port cradle"
						id="Damaged charging port cradle"
					/>
				</Col>
			</Row>
			<br />
			<small>* If any damage and/or misuse is found, please upload images of the damage/misuse.</small>
			<hr />
			<Row>
				<h5>General preparation for further checks</h5>
				<Form.Control
					hidden
					name="\/---PREPARATION/CLEANING---\/"
				/>

				<Col>
					<Form.Label className="fw-bold">Remove the left and right wax guards</Form.Label>
					<Form.Check
						type="switch"
						label="Left and right waxguard removed"
						name="Removed the wax guards"
						id="Removed the wax guards"
					/>
				</Col>
				<Col>
					<Form.Label className="fw-bold">Clean left and right earbud and cradle</Form.Label>
					<Form.Check
						type="switch"
						label="Cleaned left earbud, right earbud and cradle"
						name="Cleaned left earbud, right earbud and cradle"
						id="Cleaned left earbud, right earbud and cradle"
					/>
				</Col>
				<Col>
					<Form.Label className="fw-bold">Clean charging port/contacts of left and right earbud and cradle</Form.Label>
					<Form.Check
						type="switch"
						label="Clean charging port/contacts of left earbud, right earbud and cradle"
						name="Clean charging port/contacts of left earbud, right earbud and cradle"
						id="Clean charging port/contacts of left earbud, right earbud and cradle"
					/>
				</Col>
				<Col>
					<Form.Label className="fw-bold">Check if there is any charge in earbuds/cradle</Form.Label>
					<Form.Check
						type="switch"
						label="3 LEDS light up when opening cradle"
						name="Checked if any charge in cradle & buds"
						id="Checked if any charge in cradle & buds"
					/>
				</Col>
			</Row>
			<hr />
		</>,
	];

	const chargingChecks = [
		<Row style={{ paddingBottom: 20 }}>
			<h5>Charging RMA questions</h5>
			<Form.Control
				hidden
				name="\/---CHARGING CHECKS---\/"
			/>

			<Col>
				<Form.Label className="fw-bold">Try to charge the cradle (after cleaning the port/contacts) for at least 15 minutes</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Take both earbuds out of the cradle</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Put the left earbud back in the cradle</Form.Label>
				<Form.Check
					type="switch"
					label="Left LED did NOT turn on"
					name="Left LED did not turn on"
					id="Left LED did not turn on"
				/>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Put the right earbud back in the cradle</Form.Label>
				<Form.Check
					type="switch"
					label="Right LED did NOT turn on"
					name="Right LED did not turn on"
					id="Right LED did not turn on"
				/>
			</Col>
		</Row>,

		<hr />,
	];

	const hearingProtectionModesChecks = [
		<Row style={{ paddingBottom: 20 }}>
			<h5>Hearing protection modes RMA questions</h5>
			<Form.Control
				hidden
				name="\/---HEARING PROTECTION CHECKS---\/"
			/>
			<Col>
				<Form.Label className="fw-bold">Put one earbud in/near your ear</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Single press an earbud </Form.Label>
				<Form.Check
					type="switch"
					label="Heard a key click after single pressing"
					name="Heard a key click after single pressing"
					id="Heard a key click after single pressing"
				/>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Double press to switch between modes</Form.Label>
				<Form.Check
					type="switch"
					label="Heard a voice prompt and/or a clear difference in environment noise"
					name="Heard a voice prompt and/or a clear difference in environment noise"
					id="Heard a voice prompt and/or a clear difference in environment noise"
				/>
			</Col>
		</Row>,

		<hr />,
	];

	const noSoundOrMusicCheck = [
		<Row style={{ paddingBottom: 20 }}>
			<h5>No sound or music RMA questions</h5>
			<Form.Control
				hidden
				name="\/--NO SOUND OR MUSIC CHECKS--\/"
			/>

			<Col>
				<Form.Label className="fw-bold">Connect a phone to the earbuds</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Put one earbud in/near your ear and single press an earbud </Form.Label>
				<Form.Check
					type="switch"
					label="Heard a key click after single pressing"
					name="Heard a key click after single pressing"
					id="Heard a key click after single pressing"
				/>
			</Col>
			<Col>
				<Form.Label className="fw-bold">1. Play music from a connected phone</Form.Label>
				<Form.Label className="fw-bold">2. Increase volume to max volume on phone/earbuds</Form.Label>
			</Col>
		</Row>,

		<hr />,
	];

	const noCallCheck = [
		<Row style={{ paddingBottom: 20 }}>
			<h5>Can't call RMA questions</h5>
			<Form.Control
				hidden
				name="\/--CANT CALL CHECKS--\/"
			/>

			<Col>
				<Form.Label className="fw-bold">Connect a phone to the earbuds</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Make sure the EOP is selected as microphone and record a voice memo</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Listen back to the voice recording</Form.Label>
				<Form.Check
					type="switch"
					label="Can not clearly hear the voice recording"
					name="Can not clearly hear the voice recording"
					id="Can not clearly hear the voice recording"
				/>
			</Col>
		</Row>,

		<hr />,
	];

	const conectionIssuesCheck = [
		<Row style={{ paddingBottom: 20 }}>
			<h5>Connection RMA questions</h5>
			<Form.Control
				hidden
				name="\/---CONNECTION CHECKS---\/"
			/>
			<Col>
				<Form.Label className="fw-bold">Take both earbuds out of the cradle</Form.Label>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Put the left earbud back in the cradle</Form.Label>
				<Form.Check
					type="switch"
					label="Left LED did NOT turn on"
					name="Left LED did not turn on"
					id="Left LED did not turn on"
				/>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Put the right earbud back in the cradle</Form.Label>
				<Form.Check
					type="switch"
					label="Right LED did NOT turn on"
					name="Right LED did not turn on"
					id="Right LED did not turn on"
				/>
			</Col>
		</Row>,
		<Row style={{ paddingBottom: 20 }}>
			<Col>
				<Form.Label className="fw-bold">Long press the cradle button</Form.Label>
				<Form.Check
					type="switch"
					label="3 LEDS start flashing"
					name="3 LEDS start flashing"
					id="3 LEDS start flashing"
				/>
			</Col>
			<Col>
				<Form.Label className="fw-bold">Try to pair to the cradle/earbuds</Form.Label>
				<Form.Check
					type="switch"
					label="Right LED did NOT turn on"
					name="Right LED did not turn on"
					id="Right LED did not turn on"
				/>
			</Col>
		</Row>,

		<hr />,
	];

	useEffect(
		function getTenantsFromAPI() {
			if (token && token.length) {
				getTenants(token).then((response: Tenant[]) => {
					if (response) {
						setTenant(response.find((e) => e.id === tenantID));
					}
				});
			}
		},
		[tenantID, token]
	);

	useEffect(
		function getCustomersFromAPI() {
			if (token && token.length)
				getCustomers(token).then((response) => {
					if (response) {
						setCustomers(response);
						var temp: ListItem[] = [];
						response.forEach((cust: Customer) => {
							temp.push({ label: cust.name, value: cust.id });
						});
						setCustomersList(temp);
						setSelectedCustomer(temp?.find((e) => e.value === URLcustomerid));
					}
				});
		},
		[token]
	);

	useEffect(
		function getConsumersFromSelectedCustomer() {
			if (!receivedConsumers && selectedCustomer && token && token.length) {
				var temp: Consumer[] = [];
				getConsumers(token, selectedCustomer.value).then((result) => {
					var cons: ListItem[] = [];
					if (result)
						result.forEach((consumer: Consumer) => {
							temp.push(consumer);
							var item: ListItem = {
								label: `${consumer.given_name} ${consumer.family_name} (${consumer.short_name})`,
								value: consumer.id,
							};
							cons.push(item);
						});
					setConsumers(temp);
					setConsumersList(cons);
					setReceivedConsumers(true);
					setSelectedConsumer(cons.find((e) => e.value === URLconsumerid) ?? null);
				});
			}
		},
		[receivedConsumers, selectedCustomer, token]
	);
	useEffect(
		function getProductsFromSelectedConsumer() {
			if (!receivedProducts && selectedCustomer && selectedConsumer && token && token.length) {
				var temp: UserProduct[] = [];
				getConsumerProducts(token, selectedCustomer.value, selectedConsumer.value).then((result) => {
					var prods: ListItem[] = [];
					if (result)
						result.forEach((product: UserProduct) => {
							temp.push(product);
							var item: ListItem = {
								label: `${product.serial_cradle}`,
								value: product.id,
							};
							prods.push(item);
						});
					setProducts(temp);
					setProductsList(prods);
					setReceivedProducts(true);
					setSelectedProduct(prods.find((e) => e.value === URLproductid) ?? null);
				});
			}
		},
		[URLproductid, receivedConsumers, receivedProducts, selectedConsumer, selectedCustomer, token]
	);

	useEffect(
		function populateSelection() {
			if (URLconsumerid && URLproductid && URLcustomerid) {
				setSelectedConsumer(consumersList?.find((e) => e.value === URLconsumerid));
				setSelectedProduct(productsList?.find((e) => e.value === URLproductid));
			}
		},
		[URLconsumerid]
	);
	return (
		<>
			<Navigation />
			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<BackButton
							route="/home"
							title="homepage"
						/>
						<h1>RMA Questionnaire</h1>
						<Row>
							<Col sm={2}>
								<b>Customer:</b>
							</Col>
							<Col sm={4}>
								<Select
									isSearchable
									value={selectedCustomer}
									options={customersList}
									defaultValue={customersList?.find((e) => e.value === URLcustomerid)}
									placeholder={selectedCustomer?.label ?? "Select the customer"}
									onChange={handleCustomerChange}
								/>
							</Col>
							<Col sm={2}>
								<b>Consumer:</b>
							</Col>
							<Col sm={4}>
								<Select
									options={consumersList}
									isSearchable
									onChange={handleConsumerChange}
									placeholder={selectedConsumer?.label ?? "Select the consumer"}
									defaultValue={consumersList?.find((e) => e.value === URLconsumerid)}
									value={selectedConsumer}
								/>
							</Col>
						</Row>
						<br />
						<Row>
							<Col sm={2}>
								<b>Product:</b>
							</Col>
							<Col sm={4}>
								<Select
									noOptionsMessage={() => "Something went wrong"}
									value={selectedProduct}
									options={productsList}
									isSearchable
									onChange={(product) => setSelectedProduct(product)}
									defaultValue={productsList?.find((e) => e.value === URLproductid)}
									placeholder={selectedProduct?.label ?? "Select the product"}
								/>
							</Col>
						</Row>
						<br />
						<hr />
						{selectedProduct ? (
							<>
								<Form>
									<Row>
										<Col sm={2}>
											<b>Issue: </b>
										</Col>
										<Col sm={4}>
											<Select
												options={problems}
												placeholder="The issue that best fits the problem"
												onChange={(selected) => handleSelectIssue(selected?.value ?? "")}
											/>
										</Col>
										<br />
										{selectedProblem === "other" && (
											<Col>
												<Form.Control
													as="textarea"
													rows={2}
													placeholder="Please fill in what the issue is"
												/>
											</Col>
										)}
									</Row>
									<br />
									<Row>
										<>
											<Row style={{ paddingBottom: "50px" }}>
												<Col
													className="fw-bold"
													sm={2}
												>
													Remarks:
												</Col>
												<Col sm={4}>
													<Form.Control
														id="remarks"
														as="textarea"
														rows={3}
													/>{" "}
												</Col>

												<Col
													className="fw-bold"
													sm={5}
												>
													Upload images: <br />
													<br />
													<Form.Control
														type="file"
														multiple
														id="images"
														onChange={handleUpload}
													/>
												</Col>
											</Row>
											<h2>
												Uploaded images ({images.length}) <br />
											</h2>
											Click on an image to remove
											<br />
											<Row>
												{uploadedImagesPreview}
												<Col></Col>
												<hr />
											</Row>
											{selectedProblem !== "not_satisfied" && (
												<>
													<Row style={{ paddingBottom: "50px" }}>
														<Col
															className="fw-bold"
															sm={6}
														>
															Did you try the solutions available in the{" "}
															<a
																style={{ color: "blue" }}
																href="https://earsonly.nl/faq"
															>
																FAQ
															</a>{" "}
															to solve the problem?
														</Col>
														<Col sm={5}>
															<Form.Check
																name="Tried FAQ on EarsOnly.nl/faq"
																label="Yes, this did not solve the problem."
															/>
														</Col>
													</Row>
													<hr />
													{checks}
												</>
											)}
										</>

										<br />
										{selectedProblem !== "not_satisfied" && (
											<Col>
												<Row>
													<Form.Check
														type="radio"
														label="Didn't solve the issue, send back to EarsOnly"
														name="ntf/return"
														id="return"
													/>
												</Row>
												<Row>
													<Form.Check
														type="radio"
														label="No more trouble found"
														name="ntf/return"
														id="ntf"
													/>
												</Row>
											</Col>
										)}
									</Row>
									<Col>
										<Button
											className="right"
											size="lg"
											onClick={completeRMA}
										>
											Complete RMA
										</Button>
									</Col>
								</Form>
								{canDownloadReport && lastRMA && (
									<div className="download-pdf-button">
										<PDFDownloadLink
											document={
												<CreateRMAreport
													consumer={selectedConsumer?.label ?? ""}
													customer={selectedCustomer?.label ?? ""}
													tenant={tenant?.name ?? ""}
													rma={lastRMA}
													product={selectedProduct.label ?? ""}
													user={`${user?.email}`}
													rmanumber={rmaNumber ?? ""}
													timestamp={timestamp ?? ""}
												/>
											}
											fileName={`RMA-${selectedProduct.label}.pdf`}
										>
											{/* @ts-ignore */}
											{({ blob, url, loading, error }) =>
												loading ? (
													"Loading.."
												) : (
													<>
														<Icon.Download /> {"Download RMA report"}
													</>
												)
											}
										</PDFDownloadLink>
									</div>
								)}
							</>
						) : (
							<>
								<h2>Please select a product before continuing </h2>
								<h5>The RMA process can also be started from a profile page, this will fill in the customer, consumer and product</h5>
							</>
						)}
					</Container>
				</>
			)}
			{!isAuthenticated && <NotSignedIn />}
		</>
	);
}
